import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Customer, get } from '../../../../api';
import type { UploadFile } from 'antd/es/upload/interface';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { useUser } from '../../../../hooks';
import moment from 'moment';
import { CanShow } from '../../../../components';
type ContractsFormProps = {
  type: string;
  id?: number | null;
  // add: (v: CreateContract) => void;
  // update: (v: UpdateContract) => void;
};

const BackOrderForm: React.FC<ContractsFormProps> = (props) => {
  // const { type, id, add, update } = props;
  // const [form] = Form.useForm();
  // const statusOptions = [
  //   { label: 'VIGENTE', value: 0 },
  //   { label: 'EN ESPERA', value: 1 },
  //   { label: 'COMPLETADO', value: 2 },
  //   { label: 'EXPIRADO', value: 3 },
  // ];
  // const { user } = useUser();
  // const { isLoading: isLoadingData, data } = useQuery<Contract>([type], () =>
  //   get<Contract>('/contracts/' + id).then((e) => e.data)
  // );
  // const { isLoading: isLoadingSuppliers, data: suppliers } = useQuery<
  //   Supplier[]
  // >(['suppliers'], () => get<Supplier[]>('/suppliers').then((e) => e.data));
  // const { isLoading: isLoadingCustomers, data: customers } = useQuery<
  //   Customer[]
  // >(['customers'], () => get<Customer[]>('/customers').then((e) => e.data));

  // useEffect(() => {
  //   if (type !== 'add' && id && data) {
  //     const date = moment.unix(data.created_at);
  //     form.setFieldsValue({
  //       contractName: data.name,
  //       contractDescription: data.description,
  //       supplier: data.supplier_id,
  //       customer: data.customer_id,
  //       month: date,
  //       status: data.status,
  //     });
  //   }
  //   // setIsLoading(false);
  // }, [form, data, id, type]);

  // const fileList: UploadFile[] = [
  //   {
  //     uid: '-1',
  //     name: 'yyy.png',
  //     status: 'done',
  //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //     thumbUrl:
  //       'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //   },
  // ];
  // const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  //   // Can not select days before today and today
  //   return current && current > dayjs().endOf('day');
  // };
  // const addContractFunction = async (values: any) => {
  //   if (type === 'add' && user) {
  //     // AGREGAR CONTRATOS
  //     let contractRequest: CreateContract = {
  //       name: values.contractName,
  //       description: values.contractDescription,
  //       month: values.month.$M + 1,
  //       customer_id: user.extendedInfo ? user.extendedInfo.id : values.customer,
  //       supplier_id: values.supplier,
  //       year: values.month.$y,
  //     };
  //     add(contractRequest);
  //   } else {
  //     // EDITAR CONTRATOS
  //     if (!id) return;
  //     let contractRequest: UpdateContract = {
  //       id: id,
  //       name: values.contractName,
  //       description: values.contractDescription,
  //       status: values.status,
  //     };
  //     update(contractRequest);
  //   }
  // };

  return (
    <Spin
      // spinning={isLoadingData || isLoadingSuppliers}
      tip={<p>Cargando...</p>}
    >
      {/* <Form
        name="contracts"
        layout="vertical"
        form={form}
        onFinish={addContractFunction}
      >
        <Divider />
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col xs={20}>
            <Row gutter={16}>
              <Col xs={24} md={19}>
                <Form.Item
                  label="Nombre:"
                  name="contractName"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un nombre',
                    },
                  ]}
                >
                  <Input placeholder="Ingresa tu información" />
                </Form.Item>
              </Col>
              <Col xs={24} md={5}>
                <Form.Item
                  label="Mes y año:"
                  name="month"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione mes y año',
                    },
                  ]}
                >
                  <DatePicker
                    disabled={type !== 'add'}
                    picker="month"
                    format={'DD/MM/YYYY'}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  label="Descripción de contrato:"
                  name="contractDescription"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese una descripción',
                    },
                  ]}
                >
                  <Input placeholder="Ingresa tu información" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                {suppliers && (
                  <Form.Item
                    label="Proveedor:"
                    name="supplier"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor seleccione proveedor',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      disabled={type !== 'add'}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                    >
                      {suppliers.map((e: any) => (
                        <Select.Option key={e.id} value={e.id}>
                          {e.company_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <CanShow roles={['ADMIN', 'Financial Advisor']}>
                <Col xs={24} md={24}>
                  {customers && (
                    <Form.Item
                      label="Clientes:"
                      name="customer"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor seleccione cliente',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Selecciona una opción"
                        disabled={type !== 'add'}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                      >
                        {customers.map((e: any) => (
                          <Select.Option key={e.id} value={e.id}>
                            {e.company_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
              </CanShow>
              {type !== 'add' && (
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Estatus:"
                    name="status"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor seleccione estatus',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      disabled={type === 'add'}
                      allowClear
                      showSearch
                      options={statusOptions}
                      optionFilterProp="children"
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row justify="end">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="btn-add"
                  disabled={type === 'watch' || isLoadingData}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form> */}
    </Spin>
  );
};

export default BackOrderForm;
