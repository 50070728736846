import { Button, Row, Tag, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { User } from '../../../../api';
import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import UserForm from '../detail';
import { ExportPdfProps } from '../../../../helpers/pdf';

const label = 'usuario';
const route = '/users';
const titlePdf = 'Reporte de usuarios';

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<User>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const UsersList = () => {
  const { Text } = Typography;

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<User> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'Usuario',
        width: 100,
        ellipsis: true,
        dataIndex: 'userName',
        key: 'userName',
        ...getColumnSearchProps('userName'),
        render: (userName) => (
          <>
            {userName && (
              <Text>
                {userName.charAt(0).toUpperCase() + userName.slice(1)}
              </Text>
            )}
          </>
        ),
      },
      {
        title: 'Nombre',
        width: 100,
        ellipsis: true,
        dataIndex: 'firstName',
        key: 'firstName',
        ...getColumnSearchProps('firstName'),
        render: (firstName) => (
          <>
            {firstName && (
              <Text>
                {firstName.charAt(0).toUpperCase() + firstName.slice(1)}
              </Text>
            )}
          </>
        ),
      },
      {
        title: 'Apellido',
        width: 100,
        ellipsis: true,
        dataIndex: 'lastName',
        key: 'lastName',
        ...getColumnSearchProps('lastName'),
        render: (lastName) => (
          <>
            {lastName && (
              <Text>
                {lastName.charAt(0).toUpperCase() + lastName.slice(1)}
              </Text>
            )}
          </>
        ),
      },
      {
        title: 'Teléfono',
        width: 100,
        ellipsis: true,
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        ...getColumnSearchProps('phoneNumber'),
        render: (phoneNumber) => (
          <Text>
            {phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
          </Text>
        ),
      },
      {
        title: 'Rol',
        width: 90,
        dataIndex: 'roles',
        key: 'roles',
        filters: [
          { text: 'Admin', value: 'ADMIN' },
          { text: 'Financiero', value: 'FINANCIAL' },
          { text: 'Ventas', value: 'SALES' },
          { text: 'Cliente', value: 'CUSTOMER' },
        ],
        onFilter: (value, record) =>
          record.roles[0].startsWith(value as string),
        // onFilter: (value: string, record) => record.roles.includes(value),
        render: (roles) => (
          <Row gutter={16} style={{ width: 190 }}>
            <Tag
              color={
                roles[0] === 'ADMIN'
                  ? 'red'
                  : roles[0] === 'FINANCIAL'
                  ? 'blue'
                  : roles[0] === 'SALES'
                  ? 'green'
                  : 'yellow'
              }
              key={roles[0]}
              style={{ marginBottom: 10 }}
            >
              {roles[0] === 'ADMIN'
                ? 'Administrador'
                : roles[0] === 'FINANCIAL'
                ? 'Financiero'
                : roles[0] === 'SALES'
                ? 'Ventas'
                : 'Cliente'}
            </Tag>
          </Row>
        ),
      },
      {
        title: 'Correo electrónico',
        width: 150,
        dataIndex: 'email',
        key: 'email',
        ...getColumnSearchProps('email'),
        render: (email) => <Text>{email.toLowerCase()}</Text>,
      },
      {
        title: 'Acciones',
        key: 'sup',
        fixed: 'right',
        width: 60,
        align: 'center',
        render: (user) => (
          <>
            <Tooltip title="Ver más información">
              <Button
                type="primary"
                ghost
                shape="default"
                size="small"
                icon={<EyeOutlined />}
                onClick={() => showModal('watch', user.id)}
              />
            </Tooltip>
            <Tooltip title="Editar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#1b4289',
                  borderColor: '#1b4289',
                }}
                type="primary"
                shape="default"
                size="small"
                icon={<EditOutlined />}
                onClick={() => showModal('edit', user.id)}
              />
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#e63241',
                  borderColor: '#cf1322',
                }}
                type="primary"
                shape="default"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(user.id)}
              />
            </Tooltip>
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Usuarios',
    sheetName: 'Usuarios',
    tableName: 'Tabla de Usuarios',
    columnNames: [
      { label: 'Nombre', key: 'userName' },
      { label: 'Apellido', key: 'lastName' },
      { label: 'Teléfono', key: 'phoneNumber' },
      { label: 'Correo Electrónico', key: 'email' },
      { label: 'Rol', key: 'roles' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'Nombre', key: 'userName', width: 50 },
    { header: 'Apellido', key: 'lastName', width: 50 },
    { header: 'Teléfono', key: 'phoneNumber', width: 50 },
    { header: 'Correo Electrónico', key: 'email', width: 30 },
    { header: 'Rol', key: 'roles', width: 30 },
  ];
  const userTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: UserForm,
    pdfColumns,
    titlePdf,
  };
  const UsersTable = Table<User>(userTableProps);

  return UsersTable;
};

export default UsersList;
