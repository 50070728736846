import { Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import moment from 'moment-timezone';
import { ExportPdfProps } from '../../../../helpers/pdf';
import HistoricBalanceForm from '../detail';
import { Invoice } from '../../../../api/models/invoices';
import { useEffect, useState } from 'react';
import { formatMoney } from '../../../../utils/general';
import { get } from '../../../../api';
import { useParams } from 'react-router-dom';

const label = 'facturación';
const scrollTable = 2300;

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<Invoice>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const HistoricBalanceList = (props: any) => {
  const { customerSelected, customerName } = props;
  const { Text } = Typography;
  const [route, setRoute] = useState('/statement');
  let { type } = useParams();
  const titlePdf = `Reporte de antigüedad de saldos ${customerName} : ${customerSelected}`;

  useEffect(() => {
    setRoute(
      `/customers/${customerSelected}/statement/${
        type === undefined
          ? ''
          : type === 'por-vencer'
          ? '?balance=due'
          : '?balance=overdue'
      }`
    );
  }, [customerSelected, type]);

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<Invoice> = (props) => {
    const { getColumnSearchProps, showModal } = props;

    return [
      {
        title: '# Legal',
        dataIndex: 'invcHead2_LegalNumber',
        key: 'invcHead2_LegalNumber',
        ...getColumnSearchProps('invcHead2_LegalNumber'),
      },
      {
        title: 'Factura',
        dataIndex: 'invcHead_InvoiceNum',
        key: 'invcHead_InvoiceNum',
        ...getColumnSearchProps('invcHead_InvoiceNum'),
      },
      {
        title: 'F. Factura',
        dataIndex: 'invcHead2_InvoiceDate',
        key: 'invcHead2_InvoiceDate',
        render: (invcHead2_InvoiceDate) => (
          <>{moment.tz(invcHead2_InvoiceDate, 'UTC').format('DD/MM/YYYY')}</>
        ),
      },

      {
        title: 'Plazo en días',
        dataIndex: 'calculated_Days',
        key: 'calculated_Days',
        ...getColumnSearchProps('calculated_Days'),
      },
      {
        title: 'F. Vencimiento',
        dataIndex: 'uD02_Date02',
        key: 'uD02_Date02',
        render: (uD02_Date02) => (
          <>{moment.tz(uD02_Date02, 'UTC').format('DD/MM/YYYY')}</>
        ),
      },
      {
        title: 'Monto',
        dataIndex: 'uD02_Number01',
        key: 'uD02_Number01',
        ...getColumnSearchProps('uD02_Number01'),
        render: (uD02_Number01) => <Text>$ {formatMoney(uD02_Number01)}</Text>,
      },
      {
        title: 'Balance',
        dataIndex: 'calculated_TotalOS',
        key: 'calculated_TotalOS',
        ...getColumnSearchProps('calculated_TotalOS'),
        render: (calculated_TotalOS) => (
          <Text>$ {formatMoney(calculated_TotalOS)}</Text>
        ),
      },
      {
        title: 'Por vencer',
        dataIndex: 'calculated_Fut',
        key: 'calculated_Fut',
        ...getColumnSearchProps('calculated_Fut'),
        render: (calculated_Fut) => (
          <Text>$ {formatMoney(calculated_Fut)}</Text>
        ),
      },
      {
        title: '1-30',
        dataIndex: 'calculated_Curr',
        key: 'calculated_Curr',
        ...getColumnSearchProps('calculated_Curr'),
        render: (calculated_Curr) => (
          <Text>$ {formatMoney(calculated_Curr)}</Text>
        ),
      },
      {
        title: '31-60',
        dataIndex: 'calculated_TLM1',
        key: 'calculated_TLM1',
        ...getColumnSearchProps('calculated_TLM1'),
        render: (calculated_TLM1) => (
          <Text>$ {formatMoney(calculated_TLM1)}</Text>
        ),
      },
      {
        title: '61-90',
        dataIndex: 'calculated_TLM2',
        key: 'calculated_TLM2',
        ...getColumnSearchProps('calculated_TLM2'),
        render: (calculated_TLM2) => (
          <Text>$ {formatMoney(calculated_TLM2)}</Text>
        ),
      },
      {
        title: '>91',
        dataIndex: 'calculated_TLM3',
        key: 'calculated_TLM3',
        ...getColumnSearchProps('calculated_TLM3'),
        render: (calculated_TLM3) => (
          <Text>$ {formatMoney(calculated_TLM3)}</Text>
        ),
      },
      {
        title: 'Días',
        dataIndex: 'calculated_DaysOverdue',
        key: 'calculated_DaysOverdue',
        ...getColumnSearchProps('calculated_DaysOverdue'),
      },
      {
        title: 'Agente Cobranza',
        dataIndex: 'customer_ARAgent_c',
        key: 'customer_ARAgent_c',
        ...getColumnSearchProps('customer_ARAgent_c'),
      },
      {
        title: 'Agente Ventas',
        dataIndex: 'salesRep_Name',
        key: 'salesRep_Name',
        ...getColumnSearchProps('salesRep_Name'),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Antiguedad_de_Saldos',
    sheetName: 'Antigüedad de saldos',
    tableName: `Tabla de antigüedad de saldos ${customerName} : ${customerSelected}`,
    columnNames: [
      { label: '# Legal', key: 'invcHead2_LegalNumber' },
      { label: 'Factura', key: 'invcHead_InvoiceNum' },
      {
        label: 'F. Factura',
        key: 'invcHead2_InvoiceDate',
        format: (val) => moment.tz(val, 'UTC').format('DD/MM/YYYY'),
      },
      { label: 'Plazo en días', key: 'calculated_Days' },
      {
        label: 'F. Vencimiento',
        key: 'uD02_Date02',
        format: (val) => moment.tz(val, 'UTC').format('DD/MM/YYYY'),
      },
      {
        label: 'Monto',
        key: 'uD02_Number01',
      },
      {
        label: 'Balance',
        key: 'calculated_TotalOS',
      },
      {
        label: 'Por vencer',
        key: 'calculated_Fut',
      },
      {
        label: '1-30',
        key: 'calculated_Curr',
      },
      {
        label: '31-60',
        key: 'calculated_TLM1',
      },
      {
        label: '61-90',
        key: 'calculated_TLM2',
      },
      {
        label: '>91',
        key: 'calculated_TLM3',
      },
      { label: 'Días', key: 'calculated_DaysOverdue' },
      { label: 'Agente Cobranza', key: 'customer_ARAgent_c' },
      { label: 'Agente Ventas', key: 'salesRep_Name' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: '# Legal', key: 'invcHead2_LegalNumber' },
    { header: 'Factura', key: 'invcHead_InvoiceNum' },
    {
      header: 'F. Factura',
      key: 'invcHead2_InvoiceDate',
      format: (val) => moment.tz(val, 'UTC').format('DD/MM/YYYY'),
    },
    { header: 'P. Días', key: 'calculated_Days' },
    {
      header: 'F. Vencimiento',
      key: 'uD02_Date02',
      format: (val) => moment.tz(val, 'UTC').format('DD/MM/YYYY'),
    },
    {
      header: 'Monto',
      key: 'uD02_Number01',
      format: (val) => `$${formatMoney(val)}`,
    },
    {
      header: 'Balance',
      key: 'calculated_TotalOS',
      format: (val) => `$${formatMoney(val)}`,
    },
    {
      header: 'Por vencer',
      key: 'calculated_Fut',
      format: (val) => `$${formatMoney(val)}`,
    },
    {
      header: '1-30',
      key: 'calculated_Curr',
      format: (val) => `$${formatMoney(val)}`,
    },
    {
      header: '31-60',
      key: 'calculated_TLM1',
      format: (val) => `$${formatMoney(val)}`,
    },
    {
      header: '61-90',
      key: 'calculated_TLM2',
      format: (val) => `$${formatMoney(val)}`,
    },
    {
      header: '>91',
      key: 'calculated_TLM3',
      format: (val) => `$${formatMoney(val)}`,
    },
    { header: 'Días', key: 'calculated_DaysOverdue' },
    { header: 'Agente Cobranza', key: 'customer_ARAgent_c' },
    { header: 'Agente Ventas', key: 'salesRep_Name' },
  ];

  const invoiceTableProps = {
    label: route,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: HistoricBalanceForm,
    pdfColumns,
    titlePdf,
    scrollTable,
  };
  const InvoicesTable = Table<Invoice>(invoiceTableProps);

  return InvoicesTable;
};

export default HistoricBalanceList;
