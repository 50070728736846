import { Button, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Customer, User } from '../../../../api';
import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import UserForm from '../detail';
import { ExportPdfProps } from '../../../../helpers/pdf';

const label = 'cliente';
const route = '/customers';
const titlePdf = 'Reporte de clientes';
const scrollTable = 1000;

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<User>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const CustomersList = () => {
  const { Text } = Typography;

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<User> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'Nombre',
        width: 190,
        dataIndex: 'customer_Name',
        key: 'customer_Name',
        ...getColumnSearchProps('customer_Name'),
        render: (customer_Name) => (
          <>
            {customer_Name && (
              <Text>
                {customer_Name.charAt(0).toUpperCase() + customer_Name.slice(1)}
              </Text>
            )}
          </>
        ),
      },
      {
        title: 'Teléfono',
        width: 80,
        dataIndex: 'customer_PhoneNum',
        key: 'customer_PhoneNum',
        ...getColumnSearchProps('customer_PhoneNum'),
        render: (customer_PhoneNum) => (
          <Text>
            {customer_PhoneNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
          </Text>
        ),
      },
      {
        title: 'Correo electrónico',
        width: 150,
        dataIndex: 'customer_EMailAddress',
        key: 'customer_EMailAddress',
        ...getColumnSearchProps('customer_EMailAddress'),
        render: (customer_EMailAddress) => (
          <Text>{customer_EMailAddress.toLowerCase()}</Text>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Clientes',
    sheetName: 'Clientes',
    tableName: 'Tabla de clientes',
    columnNames: [
      {
        label: 'Nombre',
        key: 'customer_Name',
        format: (val) => val.charAt(0).toUpperCase() + val.slice(1),
      },
      {
        label: 'Teléfono',
        key: 'customer_PhoneNum',
        format: (val) => val.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
      },
      {
        label: 'Correo electrónico',
        key: 'customer_EMailAddress',
        format: (val) => val.toLowerCase(),
      },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    {
      header: 'Nombre',
      key: 'customer_Name',
      format: (val) => val.charAt(0).toUpperCase() + val.slice(1),
    },
    {
      header: 'Teléfono',
      key: 'customer_PhoneNum',
      format: (val) => val.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
    },
    {
      header: 'Correo electrónico',
      key: 'customer_EMailAddress',
      format: (val) => val.toLowerCase(),
    },
  ];

  const userTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: UserForm,
    pdfColumns,
    titlePdf,
    scrollTable,
  };
  const UsersTable = Table<Customer>(userTableProps);

  return UsersTable;
};

export default CustomersList;
