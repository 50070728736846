// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Row, Col, Layout, Card, Select, Spin } from 'antd';

import './customer-dashboard.scss';
import { useAuth, useUser } from '../../../hooks';
import { formatMoney } from '../../../utils/general';
import { Customer, get } from '../../../api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getFilteredDataFromLocalStorage,
  saveFilteredDataToLocalStorage,
} from '../../../helpers/saveFilterData';
import ChartAmount from '../../../components/menu/chart-amount';
import { useNavigate } from 'react-router-dom';

const DashboardCustomer = () => {
  const { user } = useUser();
  const [customerSelected, setCustomerSelected] = useState(
    user?.custID !== null && user?.custID !== 0 ? user?.custID : ''
  );
  const [customers, setCustomers] = useState([]);
  const [customersId, setCustomersId] = useState([]);
  const [selectDisabled, setSelectDisabled] = useState(true);
  const { logout } = useAuth();

  const filter = user.roles[0] === 'SALES' ? `?agentId=${user.userName}` : '';
  const { isLoading, data: customersget } = useQuery<Customer>(
    ['cliente'],
    () => get<Customer>('/customers/' + filter).then((e) => e.data)
  );

  const { isLoading: isLoadinBalance, data: balance } = useQuery<[]>(
    [`/customers/${customerSelected}/statementTotals`],
    () =>
      get<[]>(
        `/customers/${customerSelected}/statementTotals`,
        undefined,
        logout
      ).then((e) => e.data)
  );

  useEffect(() => {
    if (!isLoading && customersget) {
      const customersOrdenados = [...customersget];
      // let cust = customersOrdenados.filter((s) => s.customer_PortalCustomer_c);
      customersOrdenados.sort((a, b) =>
        a.customer_Name.localeCompare(b.customer_Name)
      );
      setCustomers(customersOrdenados);

      const objetosOrdenados = [...customersget].sort(
        (a, b) => a.customer_CustID - b.customer_CustID
      );
      setCustomersId(objetosOrdenados);
      setSelectDisabled(false);
    }
  }, [customersget, isLoading, user]);

  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('customer');
    if (datosObtenidos !== null && user?.role !== 'CUSTOMER') {
      setCustomerSelected(datosObtenidos);
    }
  }, [user]);

  return (
    <Layout className="admin">
      <div className="admin__container">
        <Spin tip={<p>Cargando...</p>} spinning={selectDisabled}>
          <Row gutter={16} style={{ marginBottom: 10 }} justify={'end'}>
            <Col lg={10} md={12} xs={24}>
              {customers && (
                <Select
                  style={{ width: '100%' }}
                  placeholder="Selecciona cliente"
                  optionFilterProp="children"
                  disabled={user?.role === 'CUSTOMER' || selectDisabled}
                  allowClear
                  value={
                    customerSelected?.toString() !== ''
                      ? customerSelected?.toString()
                      : null
                  }
                  onChange={(customer) => {
                    setCustomerSelected(customer);
                    saveFilteredDataToLocalStorage('customer', customer);
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {customers?.map((s: any) => (
                    <Select.Option
                      key={s.customer_CustID}
                      value={s.customer_CustID}
                    >
                      {s.customer_Name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Col>

            <Col lg={4} md={6} xs={12}>
              {customersId && (
                <Select
                  style={{ width: '100%' }}
                  placeholder="ID Cliente"
                  optionFilterProp="children"
                  disabled={user?.role === 'CUSTOMER' || selectDisabled}
                  allowClear
                  value={
                    customerSelected?.toString() !== ''
                      ? customerSelected?.toString()
                      : null
                  }
                  onChange={(customer) => {
                    setCustomerSelected(customer);
                    saveFilteredDataToLocalStorage('customer', customer);
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {customersId?.map((s: any) => (
                    <Select.Option
                      key={s.customer_CustID}
                      value={s.customer_CustID}
                    >
                      {s.customer_CustID}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Col>
          </Row>
        </Spin>

        {/* CARDS INICIO */}
        <CardAmount balance={balance} customerSelected={customerSelected} />

        {/* GRAFICA INICIO */}
        {customerSelected && (
          <ChartAmount customerSelected={customerSelected} />
        )}
      </div>
    </Layout>
  );
};

export default DashboardCustomer;

function CardAmount(props: any) {
  const { balance, customerSelected } = props;
  const navigate = useNavigate();
  return (
    <>
      {customerSelected && balance && (
        <>
          <Row className="card-amount " style={{ marginTop: 30 }}>
            <Col md={24} xs={24} sm={24} lg={8}>
              <Card
                className="animate__animated  animate__zoomIn"
                onClick={() => navigate('/antiguedad-saldos')}
              >
                <Row>
                  <Col xs={6}>
                    <div className="card-amount__circule green ">
                      <span>$</span>
                    </div>
                  </Col>
                  <Col xs={18}>
                    <p className="card-amount__price green">
                      $ {formatMoney(balance[0]?.calculated_Calculated_TotalOS)}{' '}
                      {'MXN'}
                    </p>
                    <p className="card-amount__pays">Saldo Total</p>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={24} xs={24} sm={24} lg={8}>
              <Card
                className="animate__animated  animate__zoomIn"
                onClick={() => navigate('/antiguedad-saldos/por-vencer')}
              >
                <Row>
                  <Col xs={6}>
                    <div className="card-amount__circule orange">
                      <span>$</span>
                    </div>
                  </Col>
                  <Col xs={18}>
                    <p className="card-amount__price green">
                      $ {formatMoney(balance[0]?.calculated_Fut)} {'MXN'}
                    </p>
                    <p className="card-amount__pays">Saldo Por Vencer</p>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={24} xs={24} sm={24} lg={8}>
              <Card
                className="animate__animated  animate__zoomIn"
                onClick={() => navigate('/antiguedad-saldos/vencido')}
              >
                <Row>
                  <Col xs={6}>
                    <div className="card-amount__circule red">
                      <span>$</span>
                    </div>
                  </Col>
                  <Col xs={18}>
                    <p className="card-amount__price green">
                      $ {formatMoney(balance[0]?.calculated_TotalVencido)}{' '}
                      {'MXN'}
                    </p>
                    <p className="card-amount__pays">Saldo Vencido</p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="card-amount ">
            <Col md={24} xs={24} sm={24} lg={8}>
              <Card className="animate__animated  animate__zoomIn">
                <Row>
                  <Col xs={6}>
                    <div className="card-amount__circule blue ">
                      <span>$</span>
                    </div>
                  </Col>
                  <Col xs={18}>
                    <p className="card-amount__price green">
                      $ {formatMoney(balance[0]?.customer3_CreditLimit)} {'MXN'}
                    </p>
                    <p className="card-amount__pays">Límite de crédito</p>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={24} xs={24} sm={24} lg={8}>
              <Card className="animate__animated  animate__zoomIn">
                <Row>
                  <Col xs={6}>
                    <div className="card-amount__circule blue">
                      <span>$</span>
                    </div>
                  </Col>
                  <Col xs={18}>
                    <p className="card-amount__price green">
                      $ {formatMoney(balance[0]?.calculated_Calculated_TotalOS)}{' '}
                      {'MXN'}
                    </p>
                    <p className="card-amount__pays">Crédito utilizado</p>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={24} xs={24} sm={24} lg={8}>
              <Card className="animate__animated  animate__zoomIn">
                <Row>
                  <Col xs={6}>
                    <div className="card-amount__circule blue">
                      <span>$</span>
                    </div>
                  </Col>
                  <Col xs={18}>
                    <p className="card-amount__price green">
                      ${' '}
                      {formatMoney(
                        balance.length > 0
                          ? balance[0]?.customer3_CreditLimit -
                              balance[0]?.calculated_Calculated_TotalOS
                          : 0
                      )}{' '}
                      {'MXN'}
                    </p>
                    <p className="card-amount__pays">Crédito disponible</p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
