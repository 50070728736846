import { Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';

import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ExportPdfProps } from '../../../../helpers/pdf';
import { BackOrderCustomer } from '../../../../api/models/backorders';
import BackOrderForm from '../detail';
import { useEffect, useState } from 'react';
import { formatMoney } from '../../../../utils/general';

const scrollTable = 2100;

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<BackOrderCustomer>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const BackOrderCustomerList = (props: any) => {
  const { customerSelected, customerName } = props;
  const { Text } = Typography;
  const [route, setRoute] = useState('/backorders');
  const titlePdf = `Reporte de backorders ${customerName} : ${customerSelected}`;

  useEffect(() => {
    setRoute(`/customers/${customerSelected}/backorders`);
  }, [customerSelected]);

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<BackOrderCustomer> = (props) => {
    const { getColumnSearchProps } = props;

    return [
      {
        title: 'F. Captura',
        dataIndex: 'orderHed_OrderDate',
        key: 'orderHed_OrderDate',
        ...getColumnSearchProps('orderHed_OrderDate'),
        render: (orderHed_OrderDate) => (
          <>{moment.tz(orderHed_OrderDate, 'UTC').format('DD/MM/YYYY')}</>
        ),
      },
      {
        title: 'No. Pedido',
        dataIndex: 'orderHed_OrderNum',
        key: 'orderHed_OrderNum',
        ...getColumnSearchProps('orderHed_OrderNum'),
      },
      {
        title: 'No. Parte',
        key: 'orderDtl_PartNum',
        dataIndex: 'orderDtl_PartNum',
        ...getColumnSearchProps('orderDtl_PartNum'),
      },
      {
        title: 'Descripción',
        dataIndex: 'orderDtl_LineDesc',
        key: 'orderDtl_LineDesc',
        ...getColumnSearchProps('orderDtl_LineDesc'),
      },
      {
        title: 'P. Unitario',
        dataIndex: 'orderDtl_DocUnitPrice',
        key: 'orderDtl_DocUnitPrice',
        ...getColumnSearchProps('orderDtl_DocUnitPrice'),
        render: (orderDtl_DocUnitPrice) => (
          <Text>${formatMoney(orderDtl_DocUnitPrice)}</Text>
        ),
      },
      {
        title: 'Cantidad',
        dataIndex: 'orderDtl_SellingQuantity',
        key: 'orderDtl_SellingQuantity',
        ...getColumnSearchProps('orderDtl_SellingQuantity'),
      },
      {
        title: 'Pdte. Embarque',
        dataIndex: 'calculated_PteEmbarque',
        key: 'calculated_PteEmbarque',
        ...getColumnSearchProps('calculated_PteEmbarque'),
      },
      {
        title: 'Enviado A ',
        dataIndex: 'shipTo_Name',
        key: 'shipTo_Name',
        ...getColumnSearchProps('shipTo_Name'),
      },
      {
        title: 'Warehouse',
        dataIndex: 'plant_Name',
        key: 'plant_Name',
        ...getColumnSearchProps('plant_Name'),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_BackOrders',
    sheetName: 'BackOrders',
    tableName: `Tabla de backorders ${customerName} : ${customerSelected}`,
    columnNames: [
      {
        label: 'F. Captura',
        key: 'orderHed_OrderDate',
        format: (val) => moment.tz(val, 'UTC').format('DD/MM/YYYY'),
      },
      { label: 'No. Pedido', key: 'orderHed_OrderNum' },
      { label: 'No. Parte', key: 'orderDtl_PartNum' },
      { label: 'Descripción', key: 'orderDtl_LineDesc' },
      { label: 'P. Unitario', key: 'orderDtl_DocUnitPrice' },
      { label: 'Cantidad', key: 'orderDtl_SellingQuantity' },
      { label: 'Pdte. Embarque', key: 'calculated_PteEmbarque' },
      { label: 'Enviado A', key: 'shipTo_Name' },
      { label: 'Warehouse', key: 'plant_Name' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    {
      header: 'F. Captura',
      key: 'orderHed_OrderDate',
      format: (val) => moment.tz(val, 'UTC').format('DD/MM/YYYY'),
    },
    { header: 'No. Pedido', key: 'orderHed_OrderNum' },
    { header: 'No. Parte', key: 'orderDtl_PartNum' },
    { header: 'Descripción', key: 'orderDtl_LineDesc' },
    { header: 'P. Unitario', key: 'orderDtl_DocUnitPrice' },
    { header: 'Cantidad', key: 'orderDtl_SellingQuantity' },
    { header: 'Pdte. Embarque', key: 'calculated_PteEmbarque' },
    { header: 'Enviado A', key: 'shipTo_Name' },
    { header: 'Warehouse', key: 'plant_Name' },
  ];

  const backordersTableProps = {
    label: route,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: BackOrderForm,
    pdfColumns,
    titlePdf,
    scrollTable,
  };
  const BackOrdersCustomerTable =
    Table<BackOrderCustomer>(backordersTableProps);

  return BackOrdersCustomerTable;
};

export default BackOrderCustomerList;
