import React, { useState, createContext, useEffect } from 'react';
import {  EpicorCustomer, get } from '../api';
import { useUser } from '../hooks';

type EpicorCustomerContextProps = {
  customer: EpicorCustomer | null;
  isLoading: boolean;
  refresh: () => Promise<void>;
};
const defaultProps: EpicorCustomerContextProps = {
  customer: null,
  isLoading: true,
  refresh: async () => {},
};

export const EpicorCustomerContext = createContext(defaultProps);

type EpicorCustomerProviderProps = {
  children: React.ReactNode;
};

export default function EpicorCustomerProvider(props: EpicorCustomerProviderProps) {
  const { children } = props;
  const [customer, setCustomer] = useState<EpicorCustomer | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user, isLoading: isUserLoading } = useUser();
  // const navigate = useNavigate();
  useEffect(() => {
    if(user !== null && !isNaN(user.custID) && !isUserLoading){
      loadEpicorCustomer(user.custID);
    }
  }, [isUserLoading]);

  const loadEpicorCustomer = async (customerId: number) => {
    try {
      setIsLoading(true);
      let customerResponse = await get<EpicorCustomer>('/customers/' + customerId);
      if (customerResponse.statusCode === 200) {
          setCustomer(customerResponse.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refresh = async () => {
    try {
      if (!customer) return;
      setIsLoading(true);
      let customerResponse = await get<EpicorCustomer>('/customers/' + customer?.customer_CustID);
      if (customerResponse.statusCode === 200) {
        setCustomer(customerResponse.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EpicorCustomerContext.Provider
      value={{
        customer,
        isLoading,
        refresh
      }}
    >
      {children}
    </EpicorCustomerContext.Provider>
  );
}
