import React from 'react';

import { Layout, Row, Col, Typography } from 'antd';
import logoPlanB from '../../assets/img/png/logo_planb.png';
import logo from '../../assets/img/png/encanto-logo-h.png';
import ResetPasswordForm from '../../components/general/reset-password-form';
import { useQueryParams } from '../../hooks';
// import queryString from 'query-string';

export default function ResetPassword() {
  const { Content } = Layout;
  const { Paragraph } = Typography;
  let query = useQueryParams();

  let token = query.get('token') ?? '';
  let email = query.get('email') ?? '';

  return (
    <Layout className="login">
      <Content className="login__content" style={{ padding: 20 }}>
        <div className="login__content-banner animate__animated  animate__slideInDown">
          <Row className="login-container">
            <Col span={24}>
              <img src={logo} alt="logo" style={{ width: 220 }} />
              <p
                style={{
                  fontWeight: 300,
                  lineHeight: 1.4,
                  fontSize: 18,
                  textAlign: 'center',
                  marginTop: 20,
                }}
              >
                {' '}
                Reinicie su contraseñas
              </p>
            </Col>
          </Row>
          {/* <ResetPasswordForm /> */}
          <ResetPasswordForm token={token} email={email} />
          <Row className="login-container">
            <Col>
              <Paragraph>Desarrollado por:</Paragraph>
              <img src={logoPlanB} alt="logo_planb" style={{ width: 100 }} />
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
