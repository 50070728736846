import { DashboardLayout } from '../layouts';
import { Home, Login, PasswordRecovery, ResetPassword } from '../pages';
import Administration from '../pages/administration';
import Users from '../pages/administration/users';
import BackOrders from '../pages/home/back-order';
import HistoricBalance from '../pages/home/historic-balance';
import Profile from '../pages/home/profile';
import { CanShow } from '../components';
import UnAuthorized from '../pages/home/unauthorized';
import Customers from '../pages/administration/customers';
import Invoices from '../pages/home/invoices';

type Route = {
  path: string;
  element: React.ReactNode;
  roles: string[];
  children?: Route[];
};

const routes: Route[] = [
  {
    path: '/login',
    element: <Login />,
    roles: [],
  },
  {
    path: '/forgot-password',
    element: <PasswordRecovery />,
    roles: [],
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    roles: [],
  },

  {
    path: '/',
    element: <DashboardLayout />,
    roles: [],
    children: [
      {
        path: '/',
        element: <Home />,
        roles: [],
      },
      {
        path: '/administration',
        element: (
          <CanShow
            roles={['ADMIN', 'Financial Advisor']}
            redirect="/unauthorized"
          >
            <Administration />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/administration/users',
        element: (
          <CanShow
            roles={['ADMIN', 'Financial Advisor']}
            redirect="/unauthorized"
          >
            <Users />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/administration/customers',
        element: (
          <CanShow
            roles={['ADMIN', 'Financial Advisor']}
            redirect="/unauthorized"
          >
            <Customers />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/facturacion',
        element: <Invoices />,
        roles: [],
      },
      {
        path: '/backorders',
        element: <BackOrders />,
        roles: [],
      },
      {
        path: '/antiguedad-saldos',
        element: <HistoricBalance />,
        roles: [],
      },
      {
        path: '/antiguedad-saldos/:type',
        element: <HistoricBalance />,
        roles: [],
      },
      {
        path: '/profile',
        element: <Profile />,
        roles: [],
      },
      {
        path: '/unauthorized',
        element: <UnAuthorized />,
        roles: [],
      },
    ],
  },
];

export type { Route };
export default routes;
