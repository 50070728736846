// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu, Avatar, Image, Badge } from 'antd';
import {
  PoweroffOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DownOutlined,
  UserOutlined,
  NotificationFilled,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import encantoLogo from '../../../assets/img/png/encanto-white.png';
import encantoIcon from '../../../assets/img/png/encanto-icon-w.png';
import useUser from '../../../hooks/useUser';

import './menu-top.scss';
import { useAuth } from '../../../hooks';
import { getFilteredDataFromLocalStorage } from '../../../helpers/saveFilterData';
import { useQuery } from '@tanstack/react-query';
import { Customer, get } from '../../../api';

const MenuTop = (props: { menuCollapsed: any; setMenuCollapsed: any }) => {
  const { menuCollapsed, setMenuCollapsed } = props;
  const { user } = useUser();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [profilePicture, setProfilePicture] = useState(null);
  const [infoCustomer, setInfoCustomer] = useState(null);
  const [customerSelected, setCustomerSelected] = useState(
    user?.custID !== null && user?.custID !== 0 ? user?.custID : ''
  );

  const filter = user.roles[0] === 'SALES' ? `?agentId=${user.userName}` : '';
  const { isLoading, data: customers } = useQuery<Customer>(['cliente'], () =>
    get<Customer>('/customers/' + filter).then((e) => e.data)
  );

  const menu = (
    <Menu>
      <Menu.Item key="pictureUser">
        <Image
          src={profilePicture ? profilePicture : encantoIcon}
          style={{ width: 100 }}
        />
      </Menu.Item>
      <Menu.Item key="/profile">
        <Link to="/profile">
          <UserOutlined />
          <span className="nav-text">Perfil</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        key="nameUser"
        danger
        icon={<PoweroffOutlined />}
        onClick={() => logoutUser()}
      >
        Salir
      </Menu.Item>
    </Menu>
  );
  const logoutUser = () => {
    logout();
    navigate('/login');
    // window.location.reload();
  };

  const obtenerDatos = () => {
    const datosObtenidos = getFilteredDataFromLocalStorage('customer');
    if (datosObtenidos !== null && user?.role !== 'CUSTOMER') {
      setCustomerSelected(datosObtenidos);
    }
  };
  // useEffect(() => {
  //   const datosObtenidos = getFilteredDataFromLocalStorage('customer');
  //   if (datosObtenidos !== null && user?.role !== 'CUSTOMER') {
  //     setCustomerSelected(datosObtenidos);
  //   }
  // }, [user]);

  useEffect(() => {
    if (customers) {
      const foundItem = customers.find(
        (item) => parseInt(item.customer_CustID) === customerSelected
      );
      if (foundItem) {
        setInfoCustomer(foundItem);
      }
    }
  }, [customerSelected, customers]);

  const menuNotifications = (
    <Menu>
      {infoCustomer && (
        <>
          <Menu.Item>
            <span style={{ fontWeight: 'bold' }}>Cliente: </span>
            {infoCustomer.customer_Name}
          </Menu.Item>
          <Menu.Item>
            <span style={{ fontWeight: 'bold' }}>ID:</span>{' '}
            {infoCustomer.customer_CustID}
          </Menu.Item>
          <Menu.Item>
            <span style={{ fontWeight: 'bold' }}>RFC:</span>{' '}
            {infoCustomer.customer_ResaleID}
          </Menu.Item>
          <Menu.Item>
            <span style={{ fontWeight: 'bold' }}> Teléfono: </span>
            {infoCustomer.customer_PhoneNum.replace(
              /(\d{3})(\d{3})(\d{4})/,
              '$1-$2-$3'
            )}
          </Menu.Item>
          <Menu.Item>
            <span style={{ fontWeight: 'bold' }}>Correo: </span>
            {infoCustomer.customer_EMailAddress}
          </Menu.Item>
        </>
      )}
      {!infoCustomer && (
        <Menu.Item>
          <span style={{ fontWeight: 'bold' }}>No se encontró cliente </span>
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <div className="menu-top">
      <div className="menu-top__left">
        <img
          src={encantoLogo}
          alt="apLogo"
          style={{ width: 140, marginLeft: 20 }}
        />
        <Button
          style={{ marginTop: 10 }}
          icon={menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          type="link"
          size="large"
          onClick={() => setMenuCollapsed(!menuCollapsed)}
        ></Button>
      </div>

      <div className="menu-top__right">
        <Dropdown
          trigger={['click']}
          overlay={menuNotifications}
          onClick={() => obtenerDatos()}
          // style={{ marginRight: 30 }}
        >
          <Badge style={{ marginRight: 20 }}>
            <UserOutlined
              style={{
                fontSize: 20,
                color: 'white',
                marginRight: 30,
              }}
            />
          </Badge>
        </Dropdown>
        <Dropdown overlay={menu}>
          <a href="javascript: void(0)" className="ant-dropdown-link">
            <Avatar
              shape="circle"
              src={profilePicture ? profilePicture : encantoIcon}
              style={{ marginRight: 5 }}
            />{' '}
            {/* {person?.name} */}
            <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default MenuTop;
