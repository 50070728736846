export const saveFilteredDataToLocalStorage = (
  tableName: string,
  customerId: string
) => {
  try {
    localStorage.setItem(tableName, customerId);
  } catch (error) {
    console.error('Error saving filtered data to localStorage:', error);
  }
};

export const getFilteredDataFromLocalStorage = (tableName: string) => {
  try {
    const datosFiltradosJSON = localStorage.getItem(tableName);
    return datosFiltradosJSON ? JSON.parse(datosFiltradosJSON) : null;
  } catch (error) {
    console.error(
      'Error al obtener los datos filtrados del localStorage:',
      error
    );
    return null;
  }
};
