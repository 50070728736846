// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, notification, Spin } from 'antd';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';

import './chart-amount.scss';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import DatePickerComponent from '../../general/date-picker';
import { get } from '../../../api';
import { capitalizeFirstLetter, formatMoney } from '../../../utils/general';

export default function ChartAmount(props: any) {
  const { customerSelected } = props;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { Title } = Typography;
  const colors = scaleOrdinal(schemeCategory10).range();
  const [maxTotal, setMaxTotal] = useState(0);
  const [width, setWidth] = useState('100%');
  // const [minTotal, setMinTotal] = useState(0);

  useEffect(() => {
    const startDate = moment().startOf('month').subtract(11, 'month');
    const endDate = moment().endOf('month').subtract(0, 'month');
    filterDate([startDate, endDate], customerSelected);
  }, [customerSelected]);

  const filterDate = React.useCallback(
    async (date: any, customer, currency) => {
      setIsLoading(true);
      // if (date) {
      let date2 = {
        startDate: date[0].format('MM-DD-YYYY'),
        endDate: date[1].format('MM-DD-YYYY'),
      };
      let filters =
        `/Customers/${
          customer ? customer : customerSelected
        }/TotalInvoiced?CurrencyCode=${currency ? currency : 'MXN'}` +
        `&startDate=${date2.startDate}&endDate=${date2.endDate}`;

      let totalInvoicesResponse = await get<string>(filters);
      if (totalInvoicesResponse.success) {
        totalInvoicesResponse.data.forEach((element: any, index: any) => {
          element['key'] = index;
          element['Total'] =
            element.total >= 0
              ? element.total.toFixed(2)
              : element.total.toFixed(2) * -1;
          element['DispTotal'] =
            element.total >= 0
              ? '$' +
                new Intl.NumberFormat('en', {
                  minimumFractionDigits: 2,
                }).format(element.total)
              : '$-' +
                new Intl.NumberFormat('en', {
                  minimumFractionDigits: 2,
                }).format(element.total * -1);
          element['DispDate'] = capitalizeFirstLetter(element.displayDate);
        });
        setData(totalInvoicesResponse.data);
      }
      setIsLoading(false);
    },
    [customerSelected]
  );
  useEffect(() => {
    if (data && data.length > 0) {
      const totals = data.map((obj) => obj.total);
      const max = Math.max(...totals);
      setMaxTotal(parseInt(max));
      if (data.length < 7) {
        setWidth('100%');
      } else if ((data.length >= 7) & (data.length < 12)) {
        setWidth('125%');
      } else if (data.length >= 12) {
        setWidth('150%');
      }
    }
  }, [data]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          <p className="label">{`Total: ${payload[0].payload.DispTotal}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <>
      <Row className="chart-amount">
        <Col span={24} className="chart-amount__title">
          <Title level={3}>Monto total facturado (MXN)</Title>
        </Col>
        <Col span={24} className="chart-amount__dropdown">
          <DatePickerComponent filterDate={filterDate} />
        </Col>
        <Col span={24}>
          <Spin tip={<p>Cargando...</p>} spinning={isLoading}>
            <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
              <ResponsiveContainer width={width} height={450}>
                <BarChart
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="DispDate" />
                  <YAxis
                    padding={{ top: 30 }}
                    width={100}
                    domain={[0, maxTotal]}
                    tickFormatter={(value) =>
                      '$' +
                      new Intl.NumberFormat('en', {
                        minimumFractionDigits: 2,
                      }).format(value)
                    }
                  />
                  <Tooltip content={<CustomTooltip />} />

                  <Bar
                    dataKey="Total"
                    fill="#000000"
                    label={{
                      // position: (v) => (v > 0 ? 'top' : 'bottom'),
                      position: 'top',
                      // formatter: (v) => '$' + parseFloat(v).toFixed(2),
                      dataKey: 'DispTotal',
                    }}
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colors[index % 20] as string}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Spin>
        </Col>
      </Row>
    </>
  );
}
