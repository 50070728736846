import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  Tooltip,
  Select,
  Divider,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { CreateCustomer, Customer, EditCustomer, get } from '../../../../api';
import { useQuery } from '@tanstack/react-query';
import Password from '../../../../components/general/password';

type UserProps = {
  type: string;
  id?: number | null;
  add: (v: CreateCustomer) => void;
  update: (v: EditCustomer) => void;
};
type PropsTaxRegime = {
  id: number;
  description: string;
};
const CustomerForm = (props: UserProps) => {
  const { type, id, add, update } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();

  const { isLoading: isLoadingData, data } = useQuery<Customer>([type], () =>
    get<Customer>('/customers/' + id).then((e) => e.data)
  );
  const { isLoading: isLoadingTax, data: taxRegimeArray } = useQuery<
    PropsTaxRegime[]
  >(['taxregime'], () =>
    get<PropsTaxRegime[]>('/taxRegime').then((e) => e.data)
  );
  // const { isLoading: isLoadingSuppliers, data: suppliers } = useQuery<
  //   Supplier[]
  // >(['suppliers'], () =>
  //   get<Supplier[]>('/suppliers/?hasCustomer=false').then((e) => e.data)
  // );

  useEffect(() => {
    if (type !== 'add' && id) {
      form.setFieldsValue({
        address_1: data?.address_1,
        address_2: data?.address_2,
        city: data?.city,
        company_name: data?.company_name,
        email: data?.email,
        username: data?.username,
        phone: data?.phone,
        rfc: data?.rfc,
        type: data?.type,
        state: data?.state,
        customer_type_description: data?.customer_type_description,
        zip_code: data?.zip_code,
        tax_regime: data?.tax_regime,
        suppliers: data?.supplierIds,
      });
    }
    setIsLoading(false);
  }, [form, data, id, type]);

  const addCustomerFunction = async (values: any) => {
    setIsLoading(true);
    if (type === 'add') {
      let requestBody: CreateCustomer = {
        user: {
          email: values.email,
          username: values.username,
          password: values.password,
        },
        customer: {
          address_1: values.address_1,
          address_2: values.address_2,
          city: values.city,
          company_name: values.company_name,
          phone: values.phone,
          rfc: values.rfc,
          type: values.type,
          state: values.state,
          zip_code: values.zip_code,
          tax_regime: values.tax_regime,
          supplierIds: values.suppliers,
        },
      };
      add(requestBody);
    } else {
      if (id === undefined || id === null) return;
      let requestBody: EditCustomer = {
        address_1: values.address_1,
        address_2: values.address_2,
        city: values.city,
        company_name: values.company_name,
        phone: values.phone,
        rfc: values.rfc,
        type: values.type,
        state: values.state,
        zip_code: values.zip_code,
        tax_regime: values.tax_regime,
        supplierIds: values.suppliers,
        id: id,
      };
      update(requestBody);
    }
    setIsLoading(false);
  };

  return (
    <Spin
      spinning={isLoading || isLoadingData || isLoadingTax}
      tip={<p>Cargando...</p>}
    >
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={addCustomerFunction}
      >
        <div>
          <div>
            {type === 'add' && <Divider>Usuario</Divider>}

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Nombre de cliente:"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un nombre',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type !== 'add'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Empresa:"
                  name="company_name"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un nombre de empresa',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider>Información cliente</Divider>
            <Row gutter={16}>
              <Col xs={24} md={16}>
                <Form.Item
                  label="Correo electrónico:"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un email',
                    },
                  ]}
                >
                  <Input
                    type={'email'}
                    placeholder="Ingresa tu información"
                    disabled={type !== 'add'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Tipo:"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese tipo',
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecciona una opción"
                    disabled={type === 'watch'}
                    options={[
                      { value: 1, label: 'Persona moral' },
                      { value: 2, label: 'Persona física' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            {type === 'add' && <Password type="customer" />}
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item
                  label="RFC:"
                  name="rfc"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese RFC',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={
                    <span>
                      Teléfono:&nbsp;
                      <Tooltip title="El número telefónico debe contener 10 dígitos">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa número telefónico',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                    // maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                {taxRegimeArray && (
                  <Form.Item
                    label="Régimen fiscal:"
                    name="tax_regime"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor seleccione Régimen fiscal',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      disabled={type === 'watch'}
                    >
                      {taxRegimeArray.map((e: any) => (
                        <Select.Option key={e.id} value={e.id}>
                          {e.id + ' ' + e.description}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
            </Row>
            {/* <Col xs={24} md={24}>
              {suppliers && (
                <Form.Item
                  label="Proveedores:"
                  name="suppliers"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Por favor seleccione Proveedores',
                  //   },
                  // ]}
                >
                  <Select
                    placeholder="Selecciona una opción"
                    disabled={type === 'watch'}
                    mode="multiple"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                  >
                    {suppliers.map((e: any) => (
                      <Select.Option key={e.id} value={e.id}>
                        {e.company_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col> */}
          </div>
          <Divider>Dirección</Divider>
          <div>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Estado:"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese Estado',
                    },
                  ]}
                >
                  <Input
                    placeholder="Selecciona una opción"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Ciudad:"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese Ciudad',
                    },
                  ]}
                >
                  <Input
                    placeholder="Selecciona una opción"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={
                    <span>
                      CP:&nbsp;
                      <Tooltip title="El código postal debe contener 5 dígitos">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  name="zip_code"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un código postal',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingresa tu información"
                    maxLength={5}
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={24}>
                <Form.Item
                  label="Dirección:"
                  name="address_1"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese Dirección',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item label="Dirección 2:" name="address_2">
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24}>
              <Form.Item>
                <Row justify="end">
                  <Button
                    htmlType="submit"
                    className="btn-add"
                    disabled={type === 'watch' || isLoading}
                  >
                    Guardar
                  </Button>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default CustomerForm;
