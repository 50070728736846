// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Select, Typography } from 'antd';
import GreetingsTop from '../../../components/menu/greetings-top';

import { useQuery } from '@tanstack/react-query';
import { Customer, get } from '../../../api';
import InvoicesList from './list';
import { useUser } from '../../../hooks';
import { CanShow } from '../../../components';
import {
  getFilteredDataFromLocalStorage,
  saveFilteredDataToLocalStorage,
} from '../../../helpers/saveFilterData';

export default function Invoices() {
  const { Title } = Typography;
  const { user } = useUser();
  const [customerSelected, setCustomerSelected] = useState(
    user?.custID !== null && user?.custID !== 0 ? user?.custID : ''
  );
  const [customers, setCustomers] = useState([]);
  const [customersId, setCustomersId] = useState([]);
  const [customerName, setCustomerName] = useState('');

  const filter = user?.role === 'SALES' ? `?agentId=${user.userName}` : '';
  const { isLoading, data: customersget } = useQuery<Customer[]>(
    ['cliente'],
    () => get<Customer[]>(`/customers/${filter}`).then((e) => e.data)
  );
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('customer');
    if (datosObtenidos !== null && user?.role !== 'CUSTOMER') {
      setCustomerSelected(datosObtenidos);
    }
  }, [user]);
  useEffect(() => {
    if (customersget) {
      //ORDENAR ALFABETICAMENTE
      const customersOrdenados = [...customersget];
      // let cust = customersOrdenados.filter((s) => s.customer_PortalCustomer_c);
      customersOrdenados.sort((a, b) =>
        a.customer_Name.localeCompare(b.customer_Name)
      );
      setCustomers(customersOrdenados);
      //ORDENAR ID NUMERICO
      const objetosOrdenados = [...customersget].sort(
        (a, b) => a.customer_CustID - b.customer_CustID
      );
      setCustomersId(objetosOrdenados);
    }
  }, [customersget]);
  useEffect(() => {
    if (customersget) {
      const foundItem = customersget.find(
        (item) => parseInt(item.customer_CustID) === customerSelected
      );
      if (foundItem) {
        setCustomerName(foundItem.customer_Name);
      }
    }
  }, [customerSelected, customersget]);
  return (
    <Layout>
      <GreetingsTop />
      <div className="catalog__container">
        <Row className="header-top">
          <Col xs={24} md={8} className="header-top__left">
            <Title
              level={3}
              style={{ top: 0, bottom: 0, height: '78%', margin: 'auto' }}
            >
              Antigüedad de saldos
            </Title>
          </Col>
          <Col xs={24} md={16}>
            <Row gutter={16} justify="end">
              <Col xs={24} md={16}>
                <Row style={{ marginBottom: 10 }}>
                  {customers && (
                    <Select
                      style={{ width: '100%' }}
                      placeholder="Selecciona cliente"
                      optionFilterProp="children"
                      disabled={user?.role === 'CUSTOMER'}
                      value={
                        customerSelected?.toString() !== ''
                          ? customerSelected?.toString()
                          : null
                      }
                      onChange={(customer, record) => {
                        setCustomerSelected(customer);
                        setCustomerName(record.children);
                        saveFilteredDataToLocalStorage('customer', customer);
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {customers?.map((s: any) => (
                        <Select.Option
                          key={s.customer_CustID}
                          value={s.customer_CustID}
                        >
                          {s.customer_Name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Row>
              </Col>
              <Col xs={12} md={6}>
                {customersId && (
                  <Select
                    style={{ width: '100%' }}
                    placeholder="ID Cliente"
                    optionFilterProp="children"
                    disabled={user?.role === 'CUSTOMER'}
                    allowClear
                    value={
                      customerSelected?.toString() !== ''
                        ? customerSelected?.toString()
                        : null
                    }
                    onChange={(customer, record) => {
                      setCustomerSelected(customer);
                      let customerN = customers.find(
                        (item) => item.customer_CustID === customer
                      );
                      setCustomerName(customerN.customer_Name);
                      saveFilteredDataToLocalStorage('customer', customer);
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {customersId?.map((s: any) => (
                      <Select.Option
                        key={s.customer_CustID}
                        value={s.customer_CustID}
                      >
                        {s.customer_CustID}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {customerSelected && (
        <div className="catalog__container">
          <InvoicesList
            customerSelected={customerSelected}
            customerName={customerName}
          />
        </div>
      )}
    </Layout>
  );
}
