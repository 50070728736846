// import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

type ExportPdfProps = {
  header: string;
  key: string;
  format?: (item: any) => string;
  width?: number;
};
type TableRow = {
  [key: string]: string | number;
};

const exportToPdf = (
  data: TableRow[],
  columns: ExportPdfProps[],
  title: string
) => {
  const doc = new jsPDF('landscape');

  doc.setFontSize(16);
  doc.text(title, 10, 10);

  // function getColumnStyles(width: number) {
  //   var styles: { [key: string]: { columnWidth: number } } = {};
  //   columns.forEach((col) => {
  //     styles[col.key] = { columnWidth: width };
  //   });
  //   return styles;
  // }

  // const defaultColumnWidth = 50;

  (doc as any).autoTable({
    head: [columns.map((col) => col.header)],
    body: data.map((row) =>
      columns.map((col) =>
        col.format ? col.format(row[col.key]) : row[col.key]
      )
    ),
    // columnStyles: getColumnStyles(defaultColumnWidth),
    // columnStyles: {
    //   '*': { cellWidth: 30 }, // Ancho de la primera columna
    // },
    // startY: 20,
  });
  const fileName = title.replace(' ', '_');

  doc.save(`${fileName}.pdf`);
};

export type { ExportPdfProps };
export { exportToPdf };
