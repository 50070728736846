import { Row, Col, Typography, Button } from 'antd';
import ClientIcon from '../../../assets/img/png/clientesIcon.png';
import UserIcon from '../../../assets/img/png/usersIcon.png';

import './modules.scss';
import { useNavigate } from 'react-router';

export default function Modules() {
  // const { person } = useUser();
  const catalog = [
    // {
    //   name: 'supplier',
    //   isAdmin: true,
    // },
    {
      name: 'clients',
      isAdmin: true,
    },
    {
      name: 'users',
      isAdmin: true,
    },
  ];

  return (
    <div className="modules">
      <Row gutter={[0, 15]}>
        {/* {[ROL_ADMIN].some((r) => person?.role.includes(r)) && ( */}
        <>
          {catalog.map((cat, index) => {
            if (cat.isAdmin) {
              return (
                <Col
                  span={12}
                  key={index}
                  className="modules__container animate__animated  animate__bounceInLeft"
                >
                  <ButtonAction type={cat.name} />
                </Col>
              );
            }
          })}
        </>
        {/* )} */}
        {catalog.map((cat, index) => {
          if (!cat.isAdmin) {
            return (
              <Col
                key={index}
                span={12}
                className="modules__container animate__animated  animate__bounceInLeft"
              >
                <ButtonAction type={cat.name} />
              </Col>
            );
          }
        })}
      </Row>
    </div>
  );
}

function ButtonAction(props: { type: string }) {
  const { type } = props;
  const urlImage =
    type === 'supplier' ? ClientIcon : type === 'users' ? UserIcon : ClientIcon;
  const title =
    type === 'supplier'
      ? 'Proveedores'
      : type === 'users'
      ? 'Usuarios'
      : 'Clientes';
  const { Title } = Typography;
  const navigate = useNavigate();

  const handleRedirect = () => {
    // if (type === 'supplier') {
    //   navigate('/administration/suppliers');
    // } else
    if (type === 'users') {
      navigate('/administration/users');
    } else if (type === 'clients') {
      navigate('/administration/customers');
    }
  };

  return (
    <Button
      className="modules__button"
      style={{ padding: 10 }}
      onClick={() => handleRedirect()}
    >
      <img width={60} src={urlImage} />
      <Title style={{ color: 'white', marginTop: 10 }} level={5}>
        {title}
      </Title>
    </Button>
  );
}
