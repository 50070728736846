import { basePath } from '../config';
import ApiResponse from '../models/response';

const get = async <T>(
  url: string,
  params?: any,
  onUnauthorized?: () => void
) => {
  let path = basePath + url;
  if (params !== undefined) {
    path += '?';
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const element = params[key] as string;
        path += `${key}=${element}&`;
      }
    }
    path = path.slice(0, -1);
  }
  let keyToken = process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY || '';
  let fetchParams: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem(keyToken)}`,
    },
    method: 'GET',
  };
  let response = await fetch(path, fetchParams);
  // console.log(response, 'response');

  if (response.status === 401 && onUnauthorized) {
    onUnauthorized();
  }
  let jsonText = await response.text();
  return JSON.parse(jsonText) as ApiResponse<T>;
};

export default get;
