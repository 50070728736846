import { Button, Row, Tag, Tooltip, Typography, notification } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import {
  FilePdfOutlined,
  FileOutlined,
  FileMarkdownOutlined,
} from '@ant-design/icons';
import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
// import ContractsForm from '../detail';
import moment from 'moment';
import { ExportPdfProps } from '../../../../helpers/pdf';
import HistoricBalanceForm from '../detail';
import { Invoice } from '../../../../api/models/invoices';
import { useEffect, useState } from 'react';
import { formatMoney } from '../../../../utils/general';
import { get } from '../../../../api';

const label = 'facturación';
const scrollTable = 1500;

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<Invoice>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const InvoicesList = (props: any) => {
  const { customerSelected, customerName } = props;
  const { Text } = Typography;
  const [route, setRoute] = useState('/invoices');
  const titlePdf = `Reporte de facturación ${customerName} : ${customerSelected}`;

  useEffect(() => {
    setRoute(`/customers/${customerSelected}/invoices`);
  }, [customerSelected]);

  const openPreviewFile = async (invoice: any, fileType: string) => {
    // console.log('invoiceNum', invoice.invcHead_InvoiceNum);
    let fileResponse = await get<string>(
      `/invoices/${invoice.invcHead_InvoiceNum}/${fileType}`
    ).then((e) => e);
    if (fileResponse.success) {
      window.open(fileResponse.data, '_blank', 'noreferrer');
    } else {
      notification['error']({
        message: `${fileResponse.message}`,
      });
    }
  };

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<Invoice> = (props) => {
    const { getColumnSearchProps, showModal } = props;
    return [
      {
        title: '# Legal',
        width: 150,
        dataIndex: 'invcHead_LegalNumber',
        key: 'invcHead_LegalNumber',
        ...getColumnSearchProps('invcHead_LegalNumber'),
      },
      {
        title: 'Fecha emisión',
        key: 'invcHead_InvoiceDate',
        dataIndex: 'invcHead_InvoiceDate',
        render: (invcHead_InvoiceDate) => (
          <>{moment.tz(invcHead_InvoiceDate, 'UTC').format('DD/MM/YYYY')}</>
        ),
      },
      {
        title: 'OV',
        dataIndex: 'invcHead_OrderNum',
        key: 'invcHead_OrderNum',
        ...getColumnSearchProps('invcHead_OrderNum'),
      },
      {
        title: 'Estatus',
        dataIndex: 'status',
        key: 'status',
        filters: [
          { text: 'Pagada', value: 'Pagada' },
          {
            text: 'No Pagada',
            value: 'No Pagada',
          },
          {
            text: 'Parcialmente Pagada',
            value: 'Parcialmente Pagada',
          },
        ],
        onFilter: (value, record) => record.status.startsWith(value as string),
        // ...getColumnSearchProps('status'),
      },
      {
        title: 'Moneda',
        dataIndex: 'invcHead_CurrencyCode',
        key: 'invcHead_CurrencyCode',
        ...getColumnSearchProps('invcHead_CurrencyCode'),
      },
      {
        title: 'Monto',
        dataIndex: 'invcHead_InvoiceAmt',
        key: 'invcHead_InvoiceAmt',
        ...getColumnSearchProps('invcHead_InvoiceAmt'),
        render: (invcHead_InvoiceAmt) => (
          <Text>$ {formatMoney(invcHead_InvoiceAmt)}</Text>
        ),
      },
      {
        title: 'Documento',
        dataIndex: 'invcHead_CreditMemo',
        key: 'invcHead_CreditMemo',
        ...getColumnSearchProps('invcHead_CreditMemo'),
        render: (invcHead_CreditMemo) => (
          <Text>
            {' '}
            {invcHead_CreditMemo === false ? 'Factura' : 'Nota de crédito'}
          </Text>
        ),
      },
      {
        title: 'Agente',
        dataIndex: 'salesRep_Name',
        key: 'salesRep_Name',
        ...getColumnSearchProps('salesRep_Name'),
      },
      {
        title: 'Archivos',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (id, invoice) => (
          <>
            <Tooltip title="Xml">
              <Tag color={'green'}>
                <FileMarkdownOutlined
                  onClick={() => openPreviewFile(invoice, 'xml')}
                />
              </Tag>
            </Tooltip>

            <Tooltip title="PDF">
              <Tag color={'red'}>
                <FilePdfOutlined
                  onClick={() => openPreviewFile(invoice, 'pdf')}
                />
              </Tag>
            </Tooltip>
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Facturación',
    sheetName: 'Facturación',
    tableName: `Tabla de facturación ${customerName} : ${customerSelected}`,
    columnNames: [
      // { label: '# Folio', key: 'invcHead_MXFiscalFolio' },
      {
        label: '# Legal',
        key: 'invcHead_LegalNumber',
      },
      {
        label: 'Fecha emisión',
        key: 'invcHead_InvoiceDate',
        format: (val) => moment.tz(val, 'UTC').format('DD/MM/YYYY'),
      },
      // {
      //   label: 'Fecha vencimiento',
      //   key: 'invcHead_DueDate',
      //   format: (val) => moment(val).format('DD/MM/YYYY'),
      // },
      { label: 'OV', key: 'invcHead_OrderNum' },
      { label: 'Estatus', key: 'status' },
      {
        label: 'Moneda',
        key: 'invcHead_CurrencyCode',
      },
      // {
      //   label: 'Subtotal',
      //   key: 'calculated_SubTotal',
      //   format: (val) => `$${formatMoney(val)}`,
      // },
      // {
      //   label: 'Total',
      //   key: 'invcHead_DocInvoiceAmt',
      //   format: (val) => `$${formatMoney(val)}`,
      // },
      {
        label: 'Monto',
        key: 'invcHead_InvoiceAmt',
        // format: (val) =>
        //   val.toLocaleString('es-ES', {
        //     minimumFractionDigits: 2,
        //     maximumFractionDigits: 2,
        //   }),
      },
      // {
      //   label: 'Impuestos',
      //   key: 'calculated_Impuestos',
      //   format: (val) => `$${formatMoney(val)}`,
      // },
      {
        label: 'Documento',
        key: 'invcHead_CreditMemo',
        format: (val) => (val === false ? 'Factura' : 'Nota de crédito'),
      },
      { label: 'Agente', key: 'salesRep_Name' },
      // { label: 'Días', key: 'terms_Description' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    // {
    //   header: '# Folio',
    //   key: 'invcHead_MXFiscalFolio',
    // },
    { header: '# Legal', key: 'invcHead_LegalNumber' },
    {
      header: 'Fecha emisión',
      key: 'invcHead_InvoiceDate',
      format: (val) => moment.tz(val, 'UTC').format('DD/MM/YYYY'),
    },
    // {
    //   header: 'Fecha vencimiento',
    //   key: 'invcHead_DueDate',
    //   format: (val) => moment(val).format('DD/MM/YYYY'),
    // },
    { header: 'OV', key: 'invcHead_OrderNum' },
    { header: 'Estatus', key: 'status' },
    {
      header: 'Moneda',
      key: 'invcHead_CurrencyCode',
    },
    // {
    //   header: 'Subtotal',
    //   key: 'calculated_SubTotal',
    //   format: (val) => `$${formatMoney(val)}`,
    // },
    // {
    //   header: 'Total',
    //   key: 'invcHead_DocInvoiceAmt',
    //   format: (val) => `$${formatMoney(val)}`,
    // },
    {
      header: 'Monto',
      key: 'invcHead_InvoiceAmt',
      format: (val) => `$${formatMoney(val)}`,

      // format: (val) => `$${formatMoney(val)}`,
    },
    // {
    //   header: 'Impuestos',
    //   key: 'calculated_Impuestos',
    //   format: (val) => `$${formatMoney(val)}`,
    // },
    {
      header: 'Documento',
      key: 'invcHead_CreditMemo',
      format: (val) => (val === false ? 'Factura' : 'Nota de crédito'),
    },
    { header: 'Agente', key: 'salesRep_Name' },
    // { header: 'Días', key: 'terms_Description' },
  ];

  const invoiceTableProps = {
    label: route,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: HistoricBalanceForm,
    pdfColumns,
    titlePdf,
    scrollTable,
  };
  const InvoicesTable = Table<Invoice>(invoiceTableProps);

  return InvoicesTable;
};

export default InvoicesList;
